module.exports = {
  siteTitle: "سليمان الرز - مطور مواقع وتطبيقات", // <title>
  shortSiteTitle:  "سليمان الرز - مطور مواقع وتطبيقات", // <title> ending for posts and pages
  siteDescription: "Expert in Spring Framework AWS Serverless Lambda Android React web development Microservices Java 8 EcmaScript 8 Backend Frontend developer consultant teacher writer Infrastructure as code CloudFormation cloud technologies NoOps DevOps programming development mobile devices web application ",
  siteUrl: "https://sulimanalruz.com/ar",
  // pathPrefix: "",
  siteImage: "preview.jpg",
  siteLanguage: "ar",

  /* author */
  authorName: "سليمان الرز",
  authorTwitterAccount: "sulimanruz",

  /* info */
  headerTitle: "سليمان الرز",
  headerSubTitle: "مطور مواقع وتطبيقات",

  /* manifest.json */
  manifestName: "سليمان الرز - مطور مواقع وتطبيقات",
  manifestShortName: "سليمان الرز", // max 12 characters
  manifestStartUrl: "/index.html",
  manifestBackgroundColor: "white",
  manifestThemeColor: "#7e7e7e",
  manifestDisplay: "standalone",

  // gravatar
  // Use your Gravatar image. If empty then will use src/images/jpg/avatar.jpg
  // Replace your email adress with md5-code.
  // Example https://www.gravatar.com/avatar/g.strainovic@gmail.com ->
  // gravatarImgMd5: "https://www.gravatar.com/avatar/1db853e4df386e8f699e4b35505dd8c6",
  gravatarImgMd5: "",

  // social
  authorSocialLinks: [
    { name: "github", url: "https://github.com/springsuliman" },
    { name: "twitter", url: "https://twitter.com/sulimanalruz" },
    { name: "facebook", url: "https://facebook.com/sulimanruz" }
  ]
};
