import React from 'react'
import { Form, withFormik, Field, FastField, ErrorMessage } from 'formik'
import Recaptcha from 'react-google-recaptcha'
import * as Yup from 'yup'
import { Button, Input } from '../../common'
import { recaptcha_key } from '../../../../content/meta/config'
import { Error, Center, InputField } from './styles'
import {FormattedMessage, FormattedHTMLMessage} from 'react-intl';
import TextField from "@material-ui/core/TextField";
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';

const styles = {
	root: {
	  background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
	  borderRadius: 3,
	  border: 0,
	  color: 'white',
	  height: 48,
	  padding: '0 30px',
	  boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
	},
  };

const ContactForm = props => {
const {	setFieldValue,
	isSubmitting,
	values,
	errors,
	touched,
	intl,
	handleSubmit
} = props;
if(errors.name)
errors.name = intl.formatMessage({ id: errors.name });
if(errors.email)
errors.email = intl.formatMessage({ id: errors.email });
if(errors.message)
errors.message = intl.formatMessage({ id: errors.message });
if(errors.recaptcha)
errors.recaptcha = intl.formatMessage({ id: errors.recaptcha });

return (
    <React.Fragment>
	<Form
		name="portfolio-dev"
		method="post"
		data-netlify="true"
		data-netlify-recaptcha="true"
		data-netlify-honeypot="bot-field"
		onSubmit={props.handleSubmit}
	>
		<InputField>
		<FormattedMessage id="Input_FullName" >
    {placeholder=>  
		<Input
		as={Field}
		type="text"
		name="name"
		component="input"
		aria-label="name"
		placeholder={
			placeholder}
			error={touched.name && errors.name}
						/>        }
    </FormattedMessage>
		
			<ErrorMessage component={Error} name="name" />
			
		</InputField>
		<InputField>
			<Input
				id="email"
				aria-label="email"
				component="input"
				as={Field}
				type="email"
				name="email"
				placeholder={
					intl.formatMessage({ id: 'Input_Email' })}
				error={touched.email && errors.email}
			/>
			<ErrorMessage component={Error} name="email" />
		</InputField>
		<InputField>
			<Input
				as={Field}
				component="textarea"
				aria-label="message"
				id="message"
				rows="8"
				type="text"
				name="message"
				placeholder={
					intl.formatMessage({ id: 'Input_Message' })}
				error={touched.message && errors.message}
			/>
			<ErrorMessage component={Error} name="message" />
		</InputField>
		{values.name && values.email && values.message && (
			<InputField>
				<Field
					component={Recaptcha}
					sitekey={recaptcha_key}
					name="recaptcha"
					onChange={value => setFieldValue('recaptcha', value)}
				/>
				<ErrorMessage component={Error} name="recaptcha" />
			</InputField>
		)}
		{values.success && (
			<InputField>
				<Center>
					<h4>
					<FormattedMessage id="ASAP" />
					</h4>
				</Center>
			</InputField>
		)}
		<Center>
			<Button secondary 
			type="submit" disabled={isSubmitting}>
			<FormattedMessage
                    id="submit"/>
			</Button>
		</Center>
	</Form>
	<style jsx>{`
          .text-field-custom{
			  border: 2px solid rgb(0, 0, 0) ;

			}
			.text-field-custom:focus {
				border: 2px solid rgb(0, 0, 0) ;
			}
		`}</style>
	</React.Fragment>
);
}

export default injectIntl(withFormik( 
	{
	mapPropsToValues: () => ({
		name: '',
		email: '',
		message: '',
		recaptcha: '',
		success: false,
	}),
	validationSchema: () =>
		Yup.object().shape({
			name: Yup.string().required('req_full_name'),
			email: Yup.string()
				.email('invalid_email')
				.required('req_email'),
			message: Yup.string().required('req_message'),
			recaptcha: Yup.string().required('no_robot'),
		}),
	handleSubmit: async (
		{ name, email, message, recaptcha },
		{ setSubmitting, resetForm, setFieldValue }
	) => {
		try {
/* 			const encode = data => {
				return Object.keys(data)
					.map(
						key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
					)
					.join('&')
			} */
			let response = await fetch('https://09ftr9ug8j.execute-api.us-east-1.amazonaws.com/dev/recaptcha', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					name,
					email,
					message,
					captchaResponse: recaptcha,
				
			}),
		});
			await setSubmitting(false)
			await setFieldValue('success', true)
			setTimeout(() => resetForm(), 2000)
		} catch (err) {
			setSubmitting(false)
			setFieldValue('success', false)
			alert('Something went wrong, please try again!') // eslint-disable-line
		}
	},
})(ContactForm));
