import React from 'react'
import { Container } from '../common'
import { Wrapper, Details, Thumbnail } from './styles'
import ContactForm from './ContactForm'

const Contact = props => {
	const {ContactIcon} = props;

	return (
	<Wrapper as={Container} id="contact">
	<Thumbnail>
		<ContactIcon className="contactIcon" alt="Contact" />
	</Thumbnail>
		<Details>
			<ContactForm />
		</Details>

	</Wrapper>
)};
export default Contact;
